export const DnD_ENTITY = 1;

export const ROLES = {
  SUPER: "D&D ADMIN",
  ADMIN: "ADMIN",
  EMP: "Nhân viên"
};

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const IMG_LNK = `${process.env.REACT_APP_API_URL}/upload`;

export const ERROR = {
  captcha_invalid: 'Invalid captcha.',
  pwd_old_password_wrong: 'Invalid old password.',
  new_password_not_same: 'New password must not be same with old password.',
}

export const BUTTON_LIST = [
    [
      "undo",
      "redo",
      "font",
      "fontSize",
      "formatBlock",
      "paragraphStyle",
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "textStyle",
      "removeFormat",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "link",
      "image",
      "video",
      "fullScreen",
      "showBlocks",
      "codeView",
      "preview",
    ]
  ]
  