const initialState = {
  token: null,
  user: {entity: {}},
  levels: [],
  roles: [],
  notis: {},
};

const mainReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LOGIN_END':
      var {payload = {}} = action
      return {
        ...state,
        ...payload
      };
    case 'INIT_END':
      var {payload = {}} = action
      return {
        ...state,
        ...payload
      };
    case 'LOGOUT_END':
      return initialState;
      
    default:
      return state;
  }
}

export default mainReducer;
