import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import configureStore from 'src/redux/store';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

// API
import api from 'src/core/api';
api.setDefaultBaseUrl(process.env.REACT_APP_API_URL)

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./core/layout'));

// Pages
const Login = React.lazy(() => import('./views/Login/Login'));

class App extends Component {
  render() {
    return (
      <Provider store = { configureStore() }>
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route path="/login" name="Login" render={props => <Login {...props}/>} />
                <Route path="/" name="Admin Home" render={props => {
                  api.setHistory(props.history);
                  return <DefaultLayout {...props}/>
                }} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
