import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import mainReducer from './reducers/main';

const rootReducer = combineReducers({
  main: mainReducer
});

const phase = ({START, END, FAIL}) => {
  return ({dispatch, getState}) => {
      return (next) => (action) =>
      (action && typeof action.payload === 'function')
          ? phasify(action)
          : next(action)

      function phasify({type, payload: fn}) {
          const res = fn({dispatch, getState})
          if (res && typeof res.then === 'function') {
              dispatch({type: `${type}_${START}`})
              return res.then(
                (payload) => dispatch({type: `${type}_${END}`, payload}),
                (err) => dispatch({type: `${type}_${FAIL}`, error: true, payload: err})
              )
          } else {
              return dispatch({type, payload: res})
          }
      }
  }
}

const middlewares = [
  phase({START: 'START', END: 'END', FAIL: 'FAIL'})
]

const storeEnhancers = [applyMiddleware(...middlewares)]
const finalCreateStore = compose(...storeEnhancers)(createStore)

const configureStore = () => {
  return finalCreateStore(rootReducer);
}

export default configureStore;
